import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react';
import * as server from 'config/Config';
import { CSVLink } from 'react-csv';
import {
  Box,
  Button,
  Tooltip,
  Flex,
  Select,
  useToast,
  Divider,
  Skeleton,
} from '@chakra-ui/react';
import { SearchIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import dayjs from 'dayjs';
import { ExcelDownBtn, ResetBtn } from 'styles/ComponentStyle';
import { Pagination } from 'Common/Pagination';
import { DataFetch } from '../../../Hook/DataFetch';
import SelectedTags from './SelectedTags';
import SelectedCheckBox from '../../../Common/SelectedCheckBox';
import MethodChecks from './MethodChecks';
import MembershipCheckbox from './MembershipCheckbox';
import { CheckedValid, AdminLogOut } from 'Hook/CheckedValid';
import { verifyTokenAndRefresh } from 'utill/tokenCheck';

const PayLogs = lazy(() => import('./PayLogs'));

const PaymentLog = () => {
  const toast = useToast();
  const admin = JSON.parse(localStorage.getItem('admin'));

  const headers = [
    { label: '최초 결제일자', key: 'membership.start_date' },
    { label: '회원명', key: 'user.name' },
    { label: '이메일', key: 'user.email' },
    { label: '구독상품', key: 'membership.current' || 'membership.before' },
    { label: '결제수단', key: 'membership.bill_service' },
  ];

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrent] = useState(1); //현재 페이지;
  const offset = (currentPage - 1) * 30;
  const [startDate, setStartDate] = useState(new Date('January 1, 2021'));

  const renderDayContents = date => {
    return <span title={date}>{date}</span>;
  };

  //체크된 아이템
  const [checkedItems, setCheckedItems] = useState([]);
  //체크용 id 리스트
  const [idList, setIdList] = useState([]);
  //membershipList 기본
  const [membershipList, setMembershipList] = useState(['0', '1', '3', '6']);

  //체크박스 필터용 체크
  const [filterChecked, setCheckedFilter] = useState([
    false,
    false,
    false,
    false,
  ]);
  //체크박스 필터용 체크 value
  const [filterCheckValue, setCheckedFilterValue] = useState([]);

  //pay method 기본
  const [payMethod, setPayMethod] = useState([
    'none',
    'iamport',
    'kakao',
    'innopay',
    'inicis',
    'nopassbook',
  ]);

  //pay 필터용 체크
  const [payFilter, setPayFilter] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  //pay필터용 체크 value
  const [payFilterValue, setPayFilterValue] = useState([]);

  const [selected, setSelected] = useState('default');
  const [keyword, setKeyword] = useState('');
  const [searchList, setSearchList] = useState('');

  const allChecked = filterChecked.every(Boolean);
  const payAllChecked = payFilter.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean);

  const CheckFilteredAll = e => {
    setCheckedFilter([
      e.target.checked,
      e.target.checked,
      e.target.checked,
      e.target.checked,
    ]);
    if (e.target.checked === true) {
      filterCheckValue.push('0', '1', '3', '6');

      const set = [...new Set(filterCheckValue)];
      setCheckedFilterValue(set);
      setMembershipList(set);
    }

    if (e.target.checked === false) {
      setMembershipList(['0', '1', '3', '6']);
    }
  };

  const CheckFilterValue = e => {
    if (e.target.checked === true) {
      filterCheckValue.push(e.target.value);
      setMembershipList(filterCheckValue);
    } else {
      filterCheckValue.splice(filterCheckValue.indexOf(e.target.value), 1);
      const set = [...new Set(filterCheckValue)];
      const setEvery = set.every(item => item === '');

      if (setEvery) {
        setMembershipList(['0', '1', '3', '6']);
      } else {
        setCheckedFilterValue(set);
        setMembershipList(set);
      }
    }
  };

  const CheckPayFilteredAll = e => {
    setPayFilter([
      e.target.checked,
      e.target.checked,
      e.target.checked,
      e.target.checked,
      e.target.checked,
      e.target.checked,
    ]);
    if (e.target.checked === true) {
      payFilterValue.push(
        'none',
        'iamport',
        'kakao',
        'innopay',
        'inicis',
        'nopassbook'
      );

      const set = [...new Set(payFilterValue)];
      setPayFilterValue(set);
      setPayMethod(set);
    }

    if (e.target.checked === false) {
      setPayMethod([
        'none',
        'iamport',
        'kakao',
        'innopay',
        'inicis',
        'nopassbook',
      ]);
    }
  };

  const CheckPayFilterValue = e => {
    if (e.target.checked === true) {
      payFilterValue.push(e.target.value);
      const ArrPayFilterValue = [...new Set(payFilterValue)];
      setPayMethod(ArrPayFilterValue);
    } else {
      payFilterValue.splice(payFilterValue.indexOf(e.target.value), 1);
      const set = [...new Set(payFilterValue)];
      const setEvery = set.every(item => item === '');

      if (setEvery) {
        setPayMethod([
          'none',
          'iamport',
          'kakao',
          'innopay',
          'inicis',
          'nopassbook',
        ]);
      } else {
        setPayFilterValue(set);
        setPayMethod(set);
      }
    }
  };

  const CheckAll = e => {
    setCheckedItems(e.target.checked ? idList : []);
  };

  const CheckEach = (e, id) => {
    if (e.target.checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter(item => item !== id));
    }
  };

  const HandleSelect = e => {
    setSelected(e.target.value);
  };

  const fetchData = useCallback(async () => {
    const today = new Date();
    const formatNext = dayjs(today).add(1, 'day').format('YYYY-MM-DD');

    const config = {
      method: 'post',
      url: `${server.SERVER_URL}/user/list/search`,
      headers: { Authorization: `Bearer ${admin.token}` },
      data: {
        page: currentPage,
        count: 15000,
        membershipList: membershipList,
        serviceList: payMethod,
        keyword: keyword,
        periodOption:
          selected !== 'default'
            ? {
                option: selected,
                startDate: startDate,
                endDate: formatNext,
              }
            : undefined,
      },
    };
    DataFetch(config, toast, setLoading, setSearchList, setIdList);
  }, [membershipList, payMethod, selected, startDate, keyword, currentPage]);

  const Reset = () => {
    setStartDate(new Date('January 1, 2021'));
    setCheckedFilter([false, false, false, false]);
    setCheckedFilterValue([]);
    setMembershipList(['0', '1', '3', '6']);
    setPayFilter([false, false, false, false, false, false]);
    setPayMethod([
      'none',
      'iamport',
      'kakao',
      'innopay',
      'inicis',
      'nopassbook',
    ]);
    setPayFilterValue([]);
    setSelected('default');
    setKeyword('');
  };

  useEffect(() => {
    // 토큰 상태 검증 및 갱신 로직
    const verifyAndRefreshToken = async () => {
      try {
        await verifyTokenAndRefresh();
        // 토큰 상태가 유효하면 데이터를 불러오는 로직 계속 진행
        fetchData(); // fetchData 함수를 호출해야 하는 경우
      } catch (error) {
        // 토큰 상태 검증 실패 시의 처리 로직 (예: 사용자 로그아웃 처리 및 알림 표시)
        console.error('토큰 검증 및 갱신 중 오류 발생:', error);
        toast({
          title: '인증 오류',
          description: '세션이 만료되었습니다. 다시 로그인 해주세요.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        AdminLogOut(); // 로그아웃 처리
        // 로그인 페이지로 리다이렉션 등의 추가적인 액션을 취할 수 있습니다.
      }
    };

    verifyAndRefreshToken();
  }, [toast]);

  return (
    <>
      <Box className="MemberContainer">
        <Box
          maxW="1300px"
          m="0 auto"
          bg="#fff"
          padding="36px"
          boxShadow="rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px"
        >
          <Tooltip label="작년 정기결제에서 일반결제로 변경한 시기(22.5월 말)를 기준으로 구/현을 나눠서 넣어두었습니다.">
            <InfoOutlineIcon w={5} h={5} />
          </Tooltip>
          <Flex direction="column" gap="10px" mb="20px">
            {/* 멤버십 리스트 체크 */}
            <MembershipCheckbox
              allChecked={allChecked}
              CheckFilteredAll={CheckFilteredAll}
              filterChecked={filterChecked}
              setCheckedFilter={setCheckedFilter}
              CheckFilterValue={CheckFilterValue}
            />
            {/* 결제수단 체크 */}
            <MethodChecks
              payAllChecked={payAllChecked}
              CheckPayFilteredAll={CheckPayFilteredAll}
              payFilter={payFilter}
              setPayFilter={setPayFilter}
              CheckPayFilterValue={CheckPayFilterValue}
            />
          </Flex>
          <Flex
            w="100%"
            alignItems="center"
            gridGap={15}
            direction={{ base: 'column', sm: 'row' }}
            mt={{ base: '15px', sm: '0' }}
          >
            <Select
              className="selectOption"
              defaultValue={selected}
              onChange={HandleSelect}
              maxW="300px"
            >
              <option value="default" disabled>
                날짜 기준을 먼저 선택해주세요
              </option>
              <option value="membership_start_date">결제시작일자</option>
              <option value="membership_recent_date">최근결제일자</option>
            </Select>
            <DatePicker
              className="DatePickerStyle"
              dateFormat="yyyy/MM/dd"
              selected={startDate}
              maxDate={new Date()}
              onChange={date => setStartDate(date)}
              locale={ko}
              renderDayContents={renderDayContents}
            />
          </Flex>
          <Box margin="15px 0">
            <Flex
              className="SearchFlex"
              alignItems="center"
              justify="space-between"
            >
              <input
                type={'text'}
                placeholder="검색할 키워드를 입력해주세요"
                value={keyword || ''}
                onChange={e => {
                  setKeyword(e.target.value);
                }}
              />
              <Button
                onClick={() => {
                  setCurrent(1);
                  fetchData();
                }}
              >
                <SearchIcon />
              </Button>
            </Flex>
          </Box>
          <Box textAlign="right">
            <ResetBtn onClick={Reset}>필터 초기화</ResetBtn>
            <CSVLink
              headers={headers}
              data={searchList}
              filename={'결제_현황'}
              download="결제_현황.csv"
              onClick={() => {
                if (window.confirm('다운로드 하시겠습니까?') === true) {
                  console.log('저장');
                } else {
                  return false;
                }
              }}
            >
              <ExcelDownBtn>CSV 내려받기</ExcelDownBtn>
            </CSVLink>
          </Box>

          <Divider height={'10px'} my="10px" />
          <Flex direction={'column'} gap="8px">
            <SelectedCheckBox MembershipList={membershipList} />
            <SelectedTags filterMethod={payMethod} />
          </Flex>
        </Box>
      </Box>
      <Box className="TableContainer">
        <Suspense>
          <Skeleton isLoaded={!loading} h="100%">
            <PayLogs
              offset={offset}
              searchList={searchList}
              checkedItems={checkedItems}
              CheckEach={CheckEach}
              CheckAll={CheckAll}
              idList={idList}
              isIndeterminate={isIndeterminate}
            />
          </Skeleton>
        </Suspense>
        <Pagination
          setCurrent={setCurrent}
          currentPage={currentPage}
          toast={toast}
          total={searchList.length}
        />
      </Box>
    </>
  );
};
export default PaymentLog;
