import axios from 'axios';

export async function DataFetch(config, toast,setLoading, setSearchList, setIdList){

    setLoading(true);
    await axios(config)
      .then(response => {
        const data = response.data.data;
        const orderList = data.sort(
          (a, b) => new Date(b.user.create_at) - new Date(a.user.create_at)
        );
        setSearchList(orderList);

        let idList = [];
        const ids = orderList.map((item, i) => (idList[i] = item.user.user_uid));
        setIdList(ids);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 412) {
          localStorage.clear();
            toast({
              title: '토큰이 만료됐습니다.',
              description: '새로 로그인 해주세요!',
              position: 'top-right',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
        }
      })
      .finally(()=>{
        setLoading(false);
      })
  
}