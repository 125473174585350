import React, { Suspense, useCallback, useEffect, useState, lazy } from 'react';
import axios from 'axios';
import { Box, Skeleton, useToast } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { ExcelDownBtn } from 'styles/ComponentStyle';
import * as server from 'config/Config';
import { Pagination } from 'Common/Pagination';
import { AdminLogOut } from 'Hook/CheckedValid';
import { verifyTokenAndRefresh } from 'utill/tokenCheck';

const Logs = lazy(() => import('./Logs'));

const LoginLog = () => {
  const toast = useToast();
  const admin = JSON.parse(localStorage.getItem('admin'));

  const headers = [
    { label: '로그인 일자', key: 'user.login_at' },
    { label: '회원명', key: 'user.name' },
    { label: '이메일 주소', key: 'user.email' },
    { label: '가입일자', key: 'user.create_at' },
    { label: '구독상품', key: 'membership.current' },
    { label: '구독일시', key: 'membership.start_date' },
    { label: '결제일자', key: 'membership.start_date' },
  ];
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrent] = useState(1); //현재 페이지;
  const offset = (currentPage - 1) * 30;
  const [List, setList] = useState([]);

  const fetchData = useCallback(async () => {
    const today = new Date();
    const formatNext = dayjs(today).add(1, 'day').format('YYYY-MM-DD');

    const config = {
      method: 'post',
      url: `${server.SERVER_URL}/user/list/search`,
      headers: { Authorization: `Bearer ${admin.token}` },
      data: {
        page: currentPage,
        count: 15000,
        membershipList: [0, 1, 3, 6],
        serviceList: ['iamport', 'innopay', 'nopassbook', 'none'],
        keyword: '',
        periodOption: {
          option: 'login_at',
          startDate: '2020-09-30',
          endDate: formatNext,
        },
      },
    };
    setLoading(true);
    await axios(config)
      .then(response => {
        const data = response.data.data;

        const orderList = data.sort(
          (a, b) => new Date(b.user.login_at) - new Date(a.user.login_at)
        );
        //console.log(orderList);
        setList(orderList);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 412) {
          localStorage.clear();
          toast({
            title: '토큰이 만료됐습니다.',
            description: '새로 로그인 해주세요!',
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  useEffect(() => {
    const init = async () => {
      try {
        // 토큰을 검증하고 갱신을 시도합니다
        await verifyTokenAndRefresh();
        // 성공적으로 갱신되면 데이터를 가져오는 함수를 호출합니다
        fetchData();
      } catch (error) {
        // 예를 들어 토큰 검증 실패와 같은 오류 처리
        console.error('토큰 검증 중 오류 발생:', error);
        toast({
          title: '세션 만료',
          description: '다시 로그인 해주세요.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        AdminLogOut(); // 사용자를 로그아웃 처리합니다
        // 로그인 페이지로 리다이렉트하거나 다른 정리 작업을 수행합니다
      }
    };

    // admin 객체가 존재하는지 확인한 후 진행합니다
    if (admin) {
      init();
    } else {
      toast({
        position: 'top-right',
        title: '토큰 만료',
        description: '다시 로그인 해주세요.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      AdminLogOut();
    }
  }, [toast, fetchData]);

  return (
    <Box maxW="1600px" m="0 auto" padding={{ base: '48px 24px', md: '48px' }}>
      <Box mb={25} textAlign="right">
        <CSVLink
          headers={headers}
          data={List}
          filename={'로그인_기록'}
          download="로그인_기록.csv"
          onClick={() => {
            if (window.confirm('다운로드 하시겠습니까?') === true) {
              console.log('저장');
            } else {
              return false;
            }
          }}
        >
          <ExcelDownBtn>CSV 내려받기</ExcelDownBtn>
        </CSVLink>
      </Box>
      <Box
        overflowX="auto"
        css={{
          '&::-webkit-scrollbar': {
            //스크롤바 전체영역
            width: '5px',
          },
          '&::-webkit-scrollbar-track': {
            //스크롤바 움직이는 영역
            backgroundColor: '#fff',
          },
          '&::-webkit-scrollbar-thumb': {
            //스크롤
            backgroundColor: '#E6F4F1',
            borderRadius: '5px',
          },
        }}
      >
        <Suspense>
          <Skeleton h="100%" isLoaded={!loading}>
            <Logs List={List} offset={offset} />
          </Skeleton>
        </Suspense>
      </Box>
      <Pagination
        total={List.length}
        currentPage={currentPage}
        setCurrent={setCurrent}
      />
    </Box>
  );
};

export default LoginLog;
