import React, { useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { Box, Flex,useToast,Text } from '@chakra-ui/react';
import axios from 'axios';
import { ResetBtn } from 'styles/ComponentStyle';
import styled from 'styled-components';
import { ArrowBackIcon } from '@chakra-ui/icons';
import * as server from 'config/Config';

const Container = styled(Box)`
  background-color: #fff;
  border: 1px solid #444;
  padding: 30px 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const MakeBtn = styled.button`
background-color: #618180;
border: 1px solid #618180;
border-radius: 5px;
color: #fff;
padding: 2px 25px;
font-size: 15px;
transition: all 300ms ease;
word-break: keep-all;

&:hover {
  background-color: #E6F4F1;
  border: 1px solid #E6F4F1;
  color: #474554;
}
`

const CreateChatPropmt = () => {

    const toast = useToast();
    const admin = JSON.parse(localStorage.getItem('admin'));
    const navigate = useNavigate();
    const [prompts, setPrompt] = useState({
        name: '',
        prompt: '',
    });

    const {
        name,
        prompt,
    } = prompts;

    const ChangeValues = e => {
        setPrompt({ ...prompts, [e.target.id]: e.target.value });
    };

    const MakePrompt = () => {
        const isBlank = Object.values(prompts);

        if (isBlank.includes('') === true) {
            toast({
                title: '빈 칸이 남아 있습니다.',
                description: '빈 칸을 모두 채워주세요!',
                position: 'top-right',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } else {
            const config = {
                method: "post",
                url: `${server.SERVER_URL}/prompt_npc`,
                headers: { Authorization: `Bearer ${admin.token}` },
                data: {
                    name: name,
                    text: prompt,
                },
            }
            axios(config)
                .then((response) => {
                    navigate('/chat_prompts');
                    setTimeout(
                        toast({
                            title: '성공!',
                            description: '프롬프트가 생성되었습니다.',
                            position: 'top-right',
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        }), 5000);
                })
                .catch((error) => {
                    console.log(error);
                    toast({
                        title: `${error.response.status} Error`,
                        description: `${error.response.data.message}`,
                        position: 'top-right',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });

                })
        }
    };

    const Reset = () => {
        setPrompt({
            ...prompts,
            name: '',
            prompt: '',
        });
    };

    return (
        <Box padding="48px">
            <Container>
            <Box w='100%' mb='20px'>
                <Link to='/chat_prompts'>
                    <ArrowBackIcon /> <Text as='span' fontSize={'sm'}>back</Text>
                </Link>
                </Box>
                <Flex w="100%" direction={'column'} className="makePromtLabelInput">
                    <label htmlFor="name">서비스명</label>
                    <input type="text" id="name" value={name} onChange={ChangeValues} />
                </Flex>
                <Flex
                    w="100%"
                    direction={'column'}
                    className="makePromtLabelTextarea"
                >
                    <label htmlFor="prompt">prompt </label>
                    <textarea id="prompt" value={prompt} onChange={ChangeValues} />
                </Flex>


                <Flex
                    align={'center'}
                    direction={{ base: 'column', sm: 'row' }}
                    justify={{ base: 'center', sm: 'flex-end' }}
                    mt="15px"
                    gridGap={'15px'}
                >
                    <MakeBtn cancel onClick={MakePrompt}>만들기</MakeBtn>
                    <ResetBtn onClick={Reset}>다시쓰기</ResetBtn>
                </Flex>
            </Container>
        </Box>
    );
};

export default CreateChatPropmt;
