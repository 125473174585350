import * as server from 'config/Config';
import axios from 'axios';

export async function CheckedValid(token) {
    
    const config = {
        method: "get",
        url: `${server.SERVER_URL}/profile`,
        headers: {
          Authorization: "Bearer " + token,
        },
      };

    try{
        const response = await axios(config);
        return true;
    }catch(error){
        console.log(error);
        return false
      }; 
} 



export const AdminLogOut = () => {
  localStorage.clear();
   window.location.replace('/');
  };