import { Text, Flex, Tooltip, IconButton } from '@chakra-ui/react';
import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ArrowRightIcon } from '@chakra-ui/icons';

export const Pagination = ({ setCurrent, currentPage, toast, total }) => {
    const numPages = Math.ceil(total / 30);
    return (
        <Flex m={4} align="center" justify="center">
            <Flex justify="space-between">
                <Tooltip label="First Page">
                    <IconButton
                        size="sm"
                        onClick={() => {
                            setCurrent(1);
                            if (currentPage === 1) {
                                toast({
                                    title: '맨 처음 페이지',
                                    description: '맨 처음 페이지에요!',
                                    position: 'top-right',
                                    status: 'info',
                                    duration: 5000,
                                    isClosable: true,
                                });
                            }
                        }}
                        icon={<ArrowLeftIcon h={3} w={3} />}
                        mr={4}
                    />
                </Tooltip>
                <Tooltip label="Previous Page">
                    <IconButton
                        size="sm"
                        onClick={() => {
                            setCurrent(currentPage - 1);
                        }}
                        isDisabled={currentPage === 1 && true}
                        icon={<ChevronLeftIcon h={6} w={6} />}
                    />
                </Tooltip>
            </Flex>

            <Flex align="center" flexShrink="0" ml={5} mr={5}>
                <Text>
                    <Text fontWeight="bold" as="span">
                        {currentPage}
                    </Text>{' '}
                    of{' '}
                    <Text fontWeight="bold" as="span">
                        {numPages}
                    </Text>
                </Text>
            </Flex>

            <Flex>
                <Tooltip label="Next Page">
                    <IconButton
                        size="sm"
                        onClick={() => {
                            setCurrent(currentPage + 1);
                        }}
                        isDisabled={currentPage === numPages && true}
                        icon={<ChevronRightIcon h={6} w={6} />}
                    />
                </Tooltip>
                <Tooltip label="Last Page">
                    <IconButton
                        size="sm"
                        onClick={() => {
                            setCurrent(numPages);

                            if (currentPage === numPages) {
                                toast({
                                    title: '마지막 페이지',
                                    description: '마지막 페이지에요!',
                                    position: 'top-right',
                                    status: 'info',
                                    duration: 5000,
                                    isClosable: true,
                                });
                            }
                        }}
                        icon={<ArrowRightIcon h={3} w={3} />}
                        ml={4}
                    />
                </Tooltip>
            </Flex>
        </Flex>
    )
}